import { api } from "@/config"
import { handleFailedResponse } from "@/helpers/common"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const { data } = await api.get(`${baseURI}/${id}`)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  CREATE_ITEM: async (_, params) => {
    try {
      await api.post(baseURI, { car_model: params })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  },

  UPDATE_ITEM: async (_, { id, ...params }) => {
    try {
      await api.put(`${baseURI}/${id}`, params)
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
